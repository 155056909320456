import { useEffect } from "react";
import Navigation from "./components/router/Navigation";
import "./assets/scss/App.scss";
import useToken from "../src/components/shared/hooks/useToken";
import Login from "./components/pages/Login";
import { useLocation } from "react-router-dom";
//import { removeToken } from "../src/components/shared/util/common";
//import { PermissionProvider } from "./components/shared/context/permissions-context";

function App() {
    //debugger;
    // const { token, setToken } = useToken();
    //const location = useLocation();
    //var pathName = location.pathname;
    // var pageName = pathName.substring(pathName.lastIndexOf("/") + 1);
    // var pathSplit = pathName.split("/");
    // pathSplit.shift();
    // const [breadcrumb, setBreadcrumb] = useState(pathSplit);

    // useEffect(() => {
    //     document.title = `Admin Panel`;
    //     if (pathName === "/backend/login") {
    //         document.body.classList.add("login");
    //     } else if (pathName !== "/backend/login") {
    //         document.body.classList.remove("login");
    //     }
    //     window.scrollTo(0, 0);
    //     // document.querySelector("body").classList.toggle("menu");
    // }, []);

    //Setting body class for menu collasped for different screen sizes
    // useEffect(() => {
    //     if (windowWidth < 992 && windowWidth >= 768 && pathName !== "/backend/login" && menuState) {
    //         document.body.classList.add("menu-collasped");
    //     } else if (windowWidth >= 992 && !menuState) {
    //         document.body.classList.remove("menu-collasped");
    //     } else if (windowWidth < 768 && !menuState) {
    //         document.body.classList.remove("menu-collasped");
    //     }
    //     // Side-effect logic...
    //     return () => {};
    // }, [windowWidth, pathName, menuState]);

    // Logout
    // const onHandleUserLogout = (e) => {
    //     debugger;
    //     removeToken();
    // };

    // if (!token) {
    //     debugger;
    //     return <Login setToken={setToken} />;
    // } onHandleUserLogout={onHandleUserLogout}

    return (
        <>
            {/* <PermissionProvider> */}
            <Navigation />
            {/* </PermissionProvider> */}
        </>
    );
}

export default App;
