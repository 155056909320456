import { InputGroup, FormControl, Button } from "react-bootstrap";
export default function SearchForm(props) {
    return (
        <form className="search-form" onSubmit={props.onSubmit ? props.onSubmit() : (e) => e.preventDefault()}>
            {props.dataTable ? (
                <InputGroup>
                    <FormControl className={props.inputClassName ? props.inputClassName : ""} placeholder={props.inputPlaceholder ? props.inputPlaceholder : "Search"} aria-label="Search" aria-describedby="Search" value={props.filterText ? props.filterText : ""} onChange={props.onFilter ? props.onFilter : ""} />
                    <InputGroup.Append>
                        <Button onClick={props.onClear ? props.onClear : ""} className="text-white" variant={props.buttonVariant ? props.buttonVariant : "primary"} type={props.buttonType ? props.buttonType : "submit"}>
                            {props.buttonIcon ? props.buttonIcon : "Search"}
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            ) : (
                <InputGroup>
                    <FormControl className={props.inputClassName ? props.inputClassName : ""} placeholder={props.inputPlaceholder ? props.inputPlaceholder : "Search"} aria-label="Search" aria-describedby="Search" />
                    <InputGroup.Append>
                        <Button className="text-white" variant={props.buttonVariant ? props.buttonVariant : "primary"} type={props.buttonType ? props.buttonType : "submit"}>
                            {props.buttonIcon ? props.buttonIcon : "Search"}
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            )}
        </form>
    );
}
