export const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
};

export const getUserName = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken?.username;
};

export const getUserId = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken?.id;
};

export const getPermissions = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken?.permissions;
};

var globalPermList = [];

export const getUserPermissions = () => {
    if (globalPermList != null && globalPermList.length > 0) return globalPermList;
    globalPermList = getPermissions();
    return globalPermList;
};

export const findPermissionDetail = (permList, name) => {
    //const permList = getPermissions();
    const exist = permList.find(({ ModuleItemName }) => ModuleItemName === name);
    return exist && exist.IsViewAllow === 1 ? true : false;
};

export const findPermissionDetailByIndex = (permList, name) => {
    //const permList = getPermissions();
    const exist = permList.findIndex(({ ModuleItemName }) => ModuleItemName === name);
    return exist > -1 ? true : false;
};

export const findGlobalPermissionDetail = (name) => {
    const result = globalPermList.find(({ ModuleItemName }) => ModuleItemName === name);
    return result;
};

/*export const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
};*/

export const removeToken = () => {
    globalPermList = [];
    localStorage.removeItem("token");
    localStorage.clear();
};

export const endSession = () => {
    // remove all
    globalPermList = [];
    localStorage.clear();
};
