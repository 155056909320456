import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
export default function Breadcrumbs(props) {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    let newBreadcrumb = props.breadcrumb.filter((x) => x !== "backend");

    return (
        <Breadcrumb className="breadcrumbs-wrap">
            <li className="breadcrumb-item">
                <Link to="/backend">Home</Link>
            </li>
            {newBreadcrumb.map((page, idx) => {
                //debugger;
                if (idx + 1 === newBreadcrumb.length) {
                    return (
                        <li key={idx} className="breadcrumb-item active">
                            {capitalizeFirstLetter(page)}
                        </li>
                    );
                } else {
                    return (
                        <li key={idx} className="breadcrumb-item">
                            <Link to={`/backend/${page}`}>{capitalizeFirstLetter(page)}</Link>
                        </li>
                    );
                }
            })}
        </Breadcrumb>
    );
}
