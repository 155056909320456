import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function CenteredPopup({ show, id, title, content, savetext, closetext, children, onSaveChanges, onHide }) {
    //const { show, id, title, content, savetext, closetext, children, onSaveChanges, onHide } = props;
    //debugger;
    return (
        <Modal show={show} size="lg" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className="bg-dark text-light" closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body  className="text-dark">
                {/* <h4>Centered Modal</h4> */}
                {content && <p>{content}</p>}
                {children && <div>{children}</div>}
            </Modal.Body>
            <Modal.Footer>
                <Button id={id} variant="warning" onClick={onSaveChanges}>
                    {savetext}
                </Button>
                <Button variant="dark" onClick={onHide}>
                    {closetext}
                </Button>
            </Modal.Footer>
        </Modal>
        // <Modal {...props} size="lg" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
        //     <Modal.Header closeButton>
        //         <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //         {/* <h4>Centered Modal</h4> */}
        //         {props.content && <p>{props.content}</p>}
        //         {props.children && <div>{props.children}</div>}
        //     </Modal.Body>
        //     <Modal.Footer>
        //         <Button id={props.id} variant="primary" onClick={props.onSaveChanges}>
        //             {props.savetext}
        //         </Button>
        //         <Button variant="secondary" onClick={props.onHide}>
        //             {props.closetext}
        //         </Button>
        //     </Modal.Footer>
        // </Modal>
    );
}

export default CenteredPopup;
