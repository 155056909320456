import React, { useMemo, useState, useCallback, useEffect ,style} from "react";
import { Card /*, Button*/ } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Page from "../common/Page";
import TitleBar from "../common/TitleBar";
import FileTittle from "../common/FileTittle";
import { CgClose, CgDarkMode } from "react-icons/cg";
import { MdEdit /*, MdVisibility*/, MdDelete, MdDarkMode, MdOutlineDarkMode } from "react-icons/md";
import SearchForm from "../common/SearchForm";
import DataTable,{createTheme} from "react-data-table-component";
import CenteredPopup from "../common/CenteredPopup";
import SingleGalleryRow from "../common/SingleGalleryRow";
// import { defaultProps } from '../defaultProps';
export default function FileList(props) {
    const [modalShow, setModalShow] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [List, setList] = useState([]);
    const history = useHistory();
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    //const Perm = props.Perm;
    const onAddFile = () => {
        //  debugger;
        props.setLoader(true);
        history.push("/backend/createfile");
        props.setLoader(false);
    };
    const onDeleteFile = async (e) => {
        //debugger;
        try {
            debugger;
            let deleteApiUrl = process.env.REACT_APP_IMAGEGALLERYAPI + "/" + e.target.id;
            const settings = {
                method: "DELETE",
            };
            props.setLoader(true);
            debugger;
            const fetchResponse = await fetch(deleteApiUrl, settings);
            const json = await fetchResponse.json();
            const resultIsDeleted = json ? json.IsDeleted : false;
            const dataToShow = List ? List.filter((item) => item.Id.toString() !== e.target.id) : List;
            setList(dataToShow);
            props.setLoader(false);
            setModalShow(false);
            if (resultIsDeleted === true) {
                props.showToast("Information!", "Selected file has been deleted successfully.");
            }
        } catch (err) {
            setModalShow(false);
            props.setLoader(false);
            props.showToast("Error!", "File list couldn't be loaded");
        }
    };

    // Delete User
    const handleDeleteFile = useCallback(
        async (e) => {
            debugger;
            setSelectedId(e.target.id);
            setModalShow(true);
        },
        [
            /*setUserData*/
        ]
    );
  
      
       
    
    createTheme(
    	'solarized',
        	{
        		text: {
        			primary: 'white',
        			// secondary: '#2aa198',
        		},
        		background: {
        			default: '#181822',
        		},
        		context: {
        			background: '#181822',
        			text: 'light',
        		},
        		divider: {
        			default: 'white',
        		},
        		button: {
        			default: 'white',
        			// hover: 'rgba(0,0,0,.08)',
                    hover: 'orange',
        			focus: 'rgba(255,255,255,.12)',
                    // focus: 'white',
        			disabled: 'rgba(255, 255, 255, .34)',
        		},
        		sortFocus: {
        			default: 'white',
        		},
        	},
        	'dark',
        );

    //DataTable columns (selector should be same as user data object)
    const columns = useMemo(
        () => [
            {
                name: "Name",
                selector: "FileName",
                sortable: true
            },
            // {
            //     name: "IsDeleted",
            //     selector: "IsDeleted",
            //     sortable: true,
            //     cell: (row) => {
            //         return <span>{row.IsDeleted ? "Yes" : "No"}</span>;
            //     },
            // },
           

            {
                name: "Status",
                selector: "IsVisible",
                sortable: true,
                cell: (row) => {
                    return <span>{row.IsVisible ? "active" : "Inactive"}</span>;
                    // debugger;
                },
            },
        ],
        []
    );
// debugger;
    // Creating Action Column
    const createColumns = useCallback(() => {
        return [
            ...columns,
            {
                name: "Actions",
                allowOverflow: false,
                right: true,
                cell: (row) => {
                    const handleEditFile = (e) => {
                        debugger;
                        props.setLoader(true);
                        history.push("/backend/editfile/" + row.Id);
                        // debugger;
                        props.setLoader(false);
                    };
                    return (
                        <>
                            <div className="btns-holder ">
                                {/* <button type="button" id={row.Id} className="btn btn-circle btn-sm btn-info">
                                    <MdVisibility />bg-dark
                                </button> */}
                               
                                    <button
                                        type="button"
                                        id={row.Id}
                                        onClick={handleEditFile}
                                        title="Edit"
                                        className="btn btn-circle btn-sm btn-warning"
                                    >
                                        <MdEdit />
                                    </button>
                              
                                    <button
                                        type="button"
                                        id={row.Id}
                                        onClick={handleDeleteFile}
                                        title="Delete"
                                        className="btn btn-circle btn-sm btn-dark"
                                    >
                                        <MdDelete />
                                    </button>
                               
                            </div>
                        </>
                    );
                },
            },
        ];
    }, [columns, handleDeleteFile, history, props]);

    // Search text
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const fetchData = async (page = 1 ,size = 10) => {
        // debugger;
        // let lpageNo =page;
        // let lpageSize=size;
        try {
            props.setLoader(true);
            let apiUrl = process.env.REACT_APP_IMAGEGALLERYAPI + "/getlist/" + (page - 1) + "/" + size;
            const response = await fetch(
                apiUrl /*, {
                signal: abortController.signal,
            }*/
            );
            const json = await response.json();
            const resultPages = json ? json.list : "";
            setList(resultPages);
            if (resultPages && resultPages.length > 0) {
                setTotalRows(resultPages[0].totalRows);
            } else {
                setTotalRows(0);
            }
            props.setLoader(false);
            //abortController = null;
        } catch (err) {
            props.setLoader(false);
            if (err.name === "AbortError") {
                // Handling error thrown by aborting request
            }
        }
    };

    useEffect(() => {
        let abortController = new AbortController();
        //let isComponentMounted = true;
      
        fetchData(1,11);
        // Side-effect logic...
        return () => {
            // Side-effect cleanup
            abortController.abort();
            console.log("unmounting...");
        };
    }, []);

    const handlePageChange = (page) => {
        //debugger;
        fetchData(page);
        setCurrentPage(page);
    };

    // Creating Search in DataTable
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
        return (
            <SearchForm
                dataTable={true}
                buttonIcon={<CgClose />}
                buttonType="button"
                // btnVariant="primary"
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    // Title Bar action buttons
    const actionButtons = [
        {
            btnText: "Add File",
            btnPath: "/backend/createfile/",
            btnVariant: "warning",
            btnEvent: onAddFile,
        },
    ];
    return (
        <Page breadcrumb={props.breadcrumb} pageName={props.pageName} fluid  >
          
            <FileTittle title="Files" actionButtons={actionButtons} />
            {/* <h1>Files</h1> */}
            <Card className="border-warning pb-3  border bg-dark">
          
                <Card.Body className="bg-dark border-warning rounded text-light" >
               
                    <div className="bg-dark row justify-content-end border-warning  text-light">
                        <div className="bg-dark col-12 col-md-6 col-lg-4 border-warning  text-light"></div>
                    </div>
                 
                    <DataTable
                    // className="bg-dark text-light"
                        noHeader={true}
                        highlightOnHover={true}
                        dense={true}
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeaderComponent={subHeaderComponentMemo}
                        columns={createColumns()}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationDefaultPage={currentPage}
                        onChangePage={handlePageChange}
                        data={List}
                        // striped
                        // highlightOnHover
                        // theme={ITheme.CgDarkMode}s
                        theme="solarized"
        
                    />
                </Card.Body>
            </Card>
            <CenteredPopup
                id={selectedId}
                show={modalShow}
                onHide={() => setModalShow(false)}
                title="Confirm Delete!"
                content="Are you sure you want delete this file ?"
                closetext="No"
                savetext="Confirm"
                onSaveChanges={onDeleteFile}
            />
        </Page>
    );
}
