import React from "react";

export default function SingleFileUpload(props) {
    return (
        <>

         
            
              <div className="custom-file">
         
              <label className="form-label text-light" htmlFor="userFormPassword">
                Upload file
            </label>
            </div>
            <div className="custom-file">
            <input type="file" className="custom-file-input btn btn-warning" id="customFile" onChange={props.onUpload} />
           
            <label className="custom-file-label btn btn-warning pl-5" htmlFor="customFile">
                    Choose file
                </label>
              
              
            </div>
        </>
    );
}
