import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Page from "./Page";
import { Button } from "react-bootstrap";
import SingleGalleryRow from "./SingleGalleryRow";
import TitleBar from "./TitleBar";
import { Card } from "react-bootstrap";
function CreateFile(props) {
    //debugger;
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [nameError, setNameError] = useState("");
    const [validated, setValidated] = useState(0);
    const [detail, setDetail] = useState({
        Id: 0,
        FileName: "", 
        Name: "",  
        FileServerPath:"",
        FileFullUrl:"",
        FileUrl:"",
        IsVisible: true,
    });
    const history = useHistory();
    const validate = () => {
        if (!detail.FileName) {
            setNameError("Name is mandatory.");
            return 0;
        }
        // setNameError("");
        return 0;
    };

    const handleFormChanges = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === "checkbox" ? input.checked : input.value;
        setDetail({ ...detail, [name]: value });
    };

    const singlefileSelectedHandler = (e) => {

        //let imageList = "";
        const selectedFile = e.target.files[0];
        // let selectedFiles= (selectedFile > 1 ) ? 1 / 1:selectedFile;
        // let selectedFile = (1 > 3) ? "is greater" : "is less than";
        let FileSizeInMBs = selectedFile.size ? selectedFile.size / 1024 / 1024 : 0;
        if (selectedFile && FileSizeInMBs > 8) {
            setDetail({
                ...detail, 
                ["FileName"]:"",               
                ["FileServerPath"]: "",
                ["FileFullUrl"]:"", 
                ["FileUrl"]:""   
            });
            props.showToast("Information!", "File size can't be greater than 8MB.");
            return -1;
        }
        // var fileTypes = ['txt'];  //acceptable file types
 //   selectedFile.name.split('.').pop().toLowerCase();
                //  let isSuccess = fileTypes.indexOf(filename) > -1;
        const filename =
        selectedFile
         && 
         selectedFile.name
                ? new Date().valueOf() +
                  "_" +
    selectedFile.name.replace(/\\/g, "").replace(/ /g, "").replace(/'/g, "").replace(/"/g, "").replace(/`/g, ""): ""; 
                 debugger;

                 if (selectedFile.type=="text/plain" || selectedFile.type=="application/msword") { 
        const reader = new FileReader();
        reader.onload = (e) => {
            debugger;
            // alert('file has read completely!');
            let base64 = e.target.result;
            base64 = base64.replace(/^data:file\/(txt|doc);base64,/, "");
// text = buff.toString('ascii');
            //imgRef.current = e.target.result;
            // debugger;
            try {
                debugger;
                const settings = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        file: base64,
                        filename: filename,
                    }),
                    //signal: abortController.signal,
                };
                // setUModalShow(true);
                // setZoom(zoomMaxLimit);
                fetch(process.env.REACT_APP_SERVER_DOMAIN + `/uploaddetail`, settings)
                    .then((response) => response.json())
                    .then((responseTxt) => {
                         debugger;
                        let response = JSON.parse(responseTxt); 
                        //const uploadedImageUrl = response.data.url; // get saved image url
                        const uploadedFileUrl = response.fullurl; // get saved image url
                        // const uploadedFileName = response.Filename;
                        const uploadedServerPath = response.serverpath;
                        const uploadurl = response.url;
                        setDetail({
                            ...detail,              
                            // ["FileName"]: uploadedFileName,    
                            ["FileServerPath"]:uploadedServerPath ,          
                            ["FileFullUrl"]: uploadedFileUrl,
                            ["FileUrl"]: uploadurl
                                                                              
                        });
                        if (uploadedFileUrl) {

                        }
                    })
                    .catch((e) => {
                        console.log(e);

                    });
            } catch (error) {

            }
        };
        reader.readAsText(selectedFile);
    }
    else{
        alert ("Please upload text or word file only");
    }
        //console.log(files);
    };

    function cancel ()
    {
        history.push("/backend/filelist");
    }
    const onHandleSubmit = async (e) => {
         debugger;
        e.preventDefault();
        const form = e.currentTarget;
        if (validate() < 0) {
            // e.preventDefault();
            return -1;
        }
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else if (form.checkValidity() === true) {
            setIsSubmitClicked(true);
            // setIsCancelClicked(true);
            const settings = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    Name: detail.Name,  
                    FileName: detail.FileName,  
                    FileServerPath:detail.FileServerPath,
                    FileFullUrl:detail.FileFullUrl,      
                    FileUrl:detail.FileUrl,
                    // siteId: detail.IsVisible === true ? 1 : 0,
                    siteId:1,
                    // IsVisible: detail.IsVisible === true ? 1 : 0,
                    IsVisible: true,
                    IsDeleted: false,
                    CreatedBy: 1,
                    ModifiedBy: 1,
                }),
            };
            try {
                props.setLoader(true);
                let detailSubmitUrl = process.env.REACT_APP_IMAGEGALLERYAPI;
                const fetchResponse = await fetch(detailSubmitUrl, settings);
                if (!fetchResponse.ok) {
                    // => false
                    //const statusCode = fetchResponse.status; // => 404
                    const text = await fetchResponse.text();
                    console.log(text);
                }
                //debugger;
                const retdata = await fetchResponse.json();
                let resultSet = retdata ? retdata.list : "";
                props.setLoader(false);
                if (resultSet && resultSet.length > 0 && resultSet[0].affectedRows > 0) {
                    props.showToast("Information!", "File has been saved successfully.");
                    setDetail({
                        Id: 0,
                        Name: "",  
                        FileName: "",        
                        FileServerPath:"",
                        FileFullUrl:"",
                        FileUrl:"",
                                   
                        IsVisible: true ,
                    });
                    if (resultSet.length > 0 && resultSet[resultSet.length - 1] && resultSet[resultSet.length - 1].length > 0) {
                        let sliderServerId = resultSet[resultSet.length - 1][0].RetId;
                        history.push("/backend/filelist");
                    }
                }
            } catch (e) {
                props.setLoader(false);
            }
        }
        setValidated(1);
        //console.log(detailitems);
    };

    return (
        <Page breadcrumb={props.breadcrumb} pageName={props.pageName} fluid className="bg-dark">
            <form noValidate validated={validated} onSubmit={onHandleSubmit} className="user-form bg-dark">
            <Card className="bg-dark border-warning">
                    <Card.Header>
                        <TitleBar title=" Create File" />
                    </Card.Header>
                    <Card.Body>
                <div className="form-row ">
                    <div className="form-group col-md-4">
                        <label className="form-label text-light" htmlFor="FileName">
                            Name
                        </label>
                        <input
                            name="FileName"
                            required
                            id="FileName"
                            className={`form-control ${nameError ? "is-invalid" : ""}`}
                            value={detail.FileName}
                            onChange={handleFormChanges}
                        />
                        {nameError ? <div className="invalid-feedback">{nameError}</div> : ""}
                        {!nameError && <div className="valid-feedback">Looks good!</div>}
                    </div>
                </div>
                {/* <div className="form-row"> */}
                    <div className="form-group col-md-6">
                        <label className="form-label">&nbsp;</label>
                        <div className="custom-control custom-switch">
                            <input
                                id="IsVisible"
                                name="IsVisible"
                                checked={detail.IsVisible}
                                onChange={handleFormChanges}
                                type="checkbox"
                                className="custom-control-input"
                            />
                            <label title="" htmlFor="IsVisible" className="custom-control-label text-light">
                                Active
                            </label>
                        </div>
                    </div>
                    <SingleGalleryRow
                            data={detail}
                            setRow={setDetail}
                            onUpload={singlefileSelectedHandler}
                            className="rounded"
                            // onAddSide={onAddSide}
                            // IsDisabled={IsDisabled}
                        />
                        <div className="uploaded-image  styleImagethumbnail">
                        <span className="bg-wrap" style={{backgroundImage: `url(${detail.FileUrl})`}}></span>
                        <span className="image-name sr-only">{detail.FileName}</span>
                        </div>
                {/* </div> */}
                </Card.Body>

                <div className="btn-row pt-15 text-right btns-holder">
                    <Button type="submit" className="btn btn-warning m-30" disabled={isSubmitClicked}>
                    Create
                    </Button>
                    <Button type="cancel" className="bg-dark m-30" onClick={cancel}>
                    Cancel
                    </Button>
                </div>
                </Card>
            </form>
        </Page>
    );
}

export default withRouter(CreateFile);
