import { Link } from "react-router-dom";
import Page from "../common/Page";
export default function NotFound(props) {
    return (
        <Page breadcrumb={props.breadcrumb} pageName={props.pageName} fluid>
            <h2>404 Page Not Found</h2>
            <Link to="/backend" className="btn btn-danger">
                Back to Home
            </Link>
        </Page>
    );
}
