import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import SearchForm from "./SearchForm";

import logoDark from "../../assets/images/logo-black.png";
import logoDarkSmall from "../../assets/images/logo-black-small.png";
import logoLight from "../../assets/images/logo-white.png";
import logoLightSmall from "../../assets/images/logo-white-small.png";

import iclogo from "../../assets/images/ic-logo.png";

import { CgMenuGridR, CgSearch } from "react-icons/cg";

import { getUserName } from "../shared/util/common";

export default function Header(props) {
    var userName = getUserName();
    let user = {
        // name: userName,
        name:"Admin",
        avatar: "",
    };
    const getInitials = (username) => {
        // This will concider hyphenated/apostrophe'd words as a single word. e.g Help-me will be H
        let acronym = "";
        if (username) {
            acronym = username.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
        }
        //This will fail for hyphenated/apostrophe'd words. e.g Help-me will be Hm.
        //let acronym = username.match(/\b(\w)/g).join("");
        return acronym;
    };
    // User Name initials
    let userInitials = getInitials(user.name);

    return (
        <header className="header" fixed="">
            <div className="container-fluid">
                <div className="logo-wrap">
                    {/* <div className="logo"> */}
                        {/* <Link to="/backend"> */}
                            {/* <img src={iclogo} alt="Logo" /> */}
                            {/* <h3>Fast Reading App</h3> */}
                        {/* </Link> */}
                    {/* </div> */}
                    <div className="btn-tools-wrap">
                        {/* <Link className="btn" to="#" onClick={props.handleMenuClick}>
                            {/* <CgMenuGridR className="menu" /> */}
                        {/* </Link> */} 

                        {/* <DropdownButton variant="link" id="dropdown-basic-button" title={<CgSearch />} className="search-drop">
                            <SearchForm buttonIcon={<CgSearch />} />
                        </DropdownButton> */}
                    </div>
                </div>
                <div className="user-area">
                    <Dropdown className="user-drop">
                        <Dropdown.Toggle variant="link" id="user-drop">
                            <span className="icon-name avatar">
                                {user.avatar ? <img src={user.avatar} alt={user.name} /> : userInitials}
                            </span>
                            <span className="name">{user.name}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu as="ul" flip={true} alignRight>
                            {/* <Dropdown.Item as="li">
                                <Link to="/profile">Profile</Link>
                            </Dropdown.Item>
                            <Dropdown.Item as="li">
                                <Link to="/setting">Setting</Link>
                            </Dropdown.Item> */}
                            <Dropdown.Item as="li">
                                <Link to="#" onClick={props.onHandleUserLogout}>
                                    Logout
                                </Link>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </header>
    );
}
