import Spinner from "react-bootstrap/Spinner";
export default function Loader(props) {
    return (
        <div className="spinner-wrapper">
            <Spinner animation="border" variant="primary" />
            {/* <Spinner animation="border" variant="secondary" />
            <Spinner animation="border" variant="success" />
            <Spinner animation="border" variant="info" />
            <Spinner animation="border" variant="danger" />
            <Spinner animation="border" variant="warning" /> */}
        </div>
    );
}
