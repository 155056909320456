import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import Page from "../common/Page";
import PropTypes from "prop-types";
import iclogo from "../../assets/images/ic-logo.png";
import "../../assets/scss/views/login.scss";
async function loginUser(credentials) {
    let loginApiUrl = process.env.REACT_APP_USERLOGIN;
    return fetch(loginApiUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
    }).then((data) => data.json());
}
// breadcrumb, pathName, pageName, validated,
export default function Login({ setToken }) {
    const history = useHistory();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [validated, setValidated] = useState(false);

    const handleSubmit = async (e) => {
        // debugger;
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(false);
        }
        if (form.checkValidity() === true) {
            // setLogin(true);

            if (username === "admin" && password==="admin@frapp123") {
                setValidated(true);
         
                setToken(username);
                history.push("/backend/filelist");
        }
        // debugger;
        else {
               
            alert("Please enter valid username and password");
            history.push("/backend/login");
            // debugger;
  
            }
        }
    };
    return (
        <>
            {/* breadcrumb={breadcrumb} pathName={pathName} pageName={pageName} */}
            <div class="login-page">
                <div class="page-content">
                    <Container>
                        <Row noGutters={true} className="w-100">
                            <Col className="welcome-box d-flex align-items-center justify-content-center text-center p-15" md="5">
                                <div className="align">
                                    <h1>Welcome to FastReading App</h1>
                                    <div className="logo">
                                        {/* <img src={iclogo} alt="Logo" /> */}
                                    </div>
                                </div>
                            </Col>
                            <Col className="d-flex btn-warning align-items-center justify-content-center  text-dark p-15">
                                <Form
                                    noValidate
                                    validated={validated}
                                    // onSubmit={props.handleUserLogin}
                                    onSubmit={handleSubmit}
                                    className="login-form w-100 "
                                >
                                    <h3 className="mb-15 mb-md-20">Login to Admin</h3>
                                    <Form.Group controlId="loginUsername">
                                        <Form.Control
                                            size="lg"
                                            required
                                            type="text"
                                            placeholder="Username"
                                            onChange={(e) => setUserName(e.target.value)}
                                            className="bg-dark text-light"
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="loginPassword">
                                        <Form.Control
                                            size="lg"
                                            required
                                            type="Password"
                                            placeholder="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="bg-dark text-light"

                                        />
                                    </Form.Group>
                                    <Button type="submit" variant="dark rounded">
                                        Login
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
};
