import { useState, useEffect, useMemo } from "react";
import { useLocation, useHistory, withRouter, Switch, Route } from "react-router-dom";

// Common Components
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";

// Fake User Data Function
import makeData from "../common/Data";
import createPages from "../common/PageData";

//Pages
import Home from "../pages/Home";

import Login from "../pages/Login";

import NotFound from "../pages/NotFound";

import FileList from "../pages/FileList";

import Loader from "../common/Loader";
import ICToast from "../common/ICToast";

import EditFile from "../common/EditFile";
import CreateFile from "../common/CreateFile";


import useToken from "../shared/hooks/useToken";
import { removeToken, getUserPermissions, findGlobalPermissionDetail } from "../shared/util/common";

function Navigation(/*{ onHandleUserLogout }*/) {
    const location = useLocation();
    const history = useHistory();
    const { token, setToken } = useToken();
    var pathName = location.pathname;
    var pageName = pathName.substring(pathName.lastIndexOf("/") + 1);
    var pathSplit = pathName.split("/");
    pathSplit.shift();
    const [breadcrumb, setBreadcrumb] = useState(pathSplit);
    //const [pageDetail, setPageDetail] = useState("");

    const [loader, setLoader] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [toastShow, setToastShow] = useState(false);


    
    const [page, setPage] = useState({
        Id: "",
        Title: "",
        PageUrl: "",
        MetaTitle: "",
        MetaDescription: "",
        //language: "",
        IsPublished: false,
        IsVisible: true,
    });

    const [pageData, setPageData] = useState("");
    

    // const [employeeData, setEmployeeData] = useState("");

    const showToast = (title, message) => {
        setToastTitle(title);
        setToastMessage(message);
        if (message) {
            setToastShow(true);
        }
    };

    const closeToastHandler = (el) => {
        setToastShow(false);
    };

 

    useEffect(() => {
        setBreadcrumb(pathSplit);
        //debugger;
       

        if (pathName === "/backend/editfile") {
            history.push("/backend/filelist");
        }

      
        // eslint-disable-next-line
    }, [pathName, history]);

    // Login user //Commented by Mehmood on dated : 18/11/2021 13:29PM
    const [login, setLogin] = useState(true);

    // calculating window height and width
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => {
            window.removeEventListener("resize", resizeWindow);
        };
    }, [windowWidth, windowHeight]);

    // const [validated, setValidated] = useState(false);

    // // Logout
    const handleUserLogout = (e) => {
        // debugger;
        removeToken();
        // setLogin(false);
        // setValidated(false);
    };

    // Logoin
    const handleUserLogin = (e) => {
        debugger;
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        if (form.checkValidity() === true) {
            //setLogin(true);
            history.push("/backend/filelist");
        }
        // setValidated(true);
    };

    //Menu class toggel on body
    const [menuState, setMenuState] = useState(false);
    const handleMenuClick = (e) => {
        e.preventDefault();
        setMenuState(!menuState);
    };
    useEffect(() => {
        if (menuState) {
            document.querySelector("body").classList.remove("menu-collasped");
        } else {
            document.querySelector("body").classList.add("menu-collasped");
        }
        // Side-effect logic...
        return () => {};
    }, [menuState]);

    // Setting body class for login page and redirect to login if not loged in
    // useEffect(() => {
    //     //debugger;
    //      if (!login) {
    //         history.push("/backend/login");
    //     } 
    //     if (pathName === "/backend/login") {
    //         document.body.classList.add("login");
    //     } else if (pathName !== "/backend/login") {
    //         document.body.classList.remove("login");
    //     }
    //     if (pathName.indexOf("/backend/viewpagesetting") >= 0 || pathName.indexOf("/backend/editpagesetting") >= 0) {
    //         document.body.classList.add("full-page");
    //     } else if (pathName.indexOf("/backend/viewpagesetting") < 0 || pathName.indexOf("/backend/editpagesetting") < 0) {
    //         document.body.classList.remove("full-page");
    //     }
    //     // Side-effect logic...
    //     return () => {};
    // }, [pathName , login, history]);

    //Setting body class for menu collasped for different screen sizes
    // useEffect(() => {
    //     if (windowWidth < 992 && windowWidth >= 768 && pathName !== "/backend/login" && menuState) {
    //         document.body.classList.add("menu-collasped");
    //     } else if (windowWidth >= 992 && !menuState) {
    //         document.body.classList.remove("menu-collasped");
    //     } else if (windowWidth < 768 && !menuState) {
    //         document.body.classList.remove("menu-collasped");
    //     }
    //     // Side-effect logic...
    //     return () => {};
    // }, [windowWidth, pathName, menuState]);

    const onHandleUserLogout = (e) => {
        //debugger;
        // removeToken();
        history.push("/backend/login");
    };
//    debugger;
     if (!token) {
        // alert("Enter valid username and password");
         return <Login setToken={setToken} />;
    }
  

    return (
        <div className="wrapper">
            {/* {pathName === "/backend/login" ||
            pathName.indexOf("/backend/viewpagesetting") >= 0 ||
            pathName.indexOf("/backend/editpagesetting") >= 0 ? (
                ""
            ) : ( */}
                <Header handleMenuClick={handleMenuClick} onHandleUserLogout={onHandleUserLogout} />
            {/* )} */}
            {/* {pathName === "/backend/login" ||
            pathName.indexOf("/backend/viewpagesetting") >= 0 ||
            pathName.indexOf("/backend/editpagesetting") >= 0
                ? ""
                : /*permList && */ 
            // ( */}
                    //   <Sidebar
                    //       handleMenuClick={handleMenuClick}
                    //   />
                    //   isImageGalleryAllow={isImageGalleryPerm && isImageGalleryPerm.IsViewAllow ? isImageGalleryPerm.IsViewAllow : 2}
                //   )
                  }
            {/* <ICToast show={toastShow} closeHandler={closeToastHandler} Title={toastTitle} Message={toastMessage} /> */}
            {loader ? <Loader /> : ""}
            <main className="main">
                <Switch location={location}>
                    <Route exact path="/backend">
                        <Home breadcrumb={breadcrumb} pathName={pathName} pageName={pageName} />
                    </Route>
                    <Route exact path="/backend/login">
                        <Login setToken={setToken} />
                        {/* validated={validated} */}
                        {/* <Login handleUserLoginz={handleUserLogin}  pathName={pathName} pageName={pageName} />                    */}
                    </Route>
                  
                    
                        <Route path="/backend/filelist">
                            <FileList
                                loader={loader}
                                setLoader={setLoader}
                                showToast={showToast}
                                breadcrumb={breadcrumb}
                                pathName={pathName}
                                pageName={pageName}
                                
                            />
                        </Route>
                    

                        <Route path="/backend/createfile">
                            <CreateFile
                                loader={loader}
                                setLoader={setLoader}
                                showToast={showToast}
                                breadcrumb={breadcrumb}
                                pathName={pathName}
                                pageName={pageName}
                            />
                        </Route>
                        <Route path="/backend/editfile/:id">
                            <EditFile
                                loader={loader}
                                setLoader={setLoader}
                                showToast={showToast}
                                breadcrumb={breadcrumb}
                                pathName={pathName}
                                pageName={pageName}
                            />
                        </Route>                 
                    <Route exact path="/backend/*">
                        <NotFound breadcrumb={breadcrumb} pathName={pathName} pageName={pageName} />
                    </Route>
                </Switch>
            </main>
        </div>
    );
}
export default withRouter(Navigation);
