import React from "react";
import SingleFileUpload from "./SingleFileUpload";
import { Button } from "react-bootstrap";

export default function SingleGalleryRow(props) {
    return (
        <>
            <div className="form-row">                                                
                <div className="form-group col-md-3">
                    <SingleFileUpload onUpload={props.onUpload} />
                </div>
            </div>
            <div className="form-row justify-content-end">
                {/* <Button variant="secondary" disabled={props.IsDisabled} onClick={props.onAddSide}>
                    {/* Add Image */}
                {/* </Button> */} 
            </div>
        </>
    );
}
