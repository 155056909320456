import { useEffect } from "react";
import { Container } from "react-bootstrap";

import Breadcrumbs from "../common/Breadcrumbs";
export default function Page(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container className={`page-content ${props.className ? props.className : ""} `} fluid={props.fluid ? "fluid" : ""}>
            {props.pageName === "" || props.pathName === "/backend/login" ? (
                ""
            ) : (
                <Breadcrumbs breadcrumb={props.breadcrumb} pageName={props.pageName} />
            )}

            {props.children}
        </Container>
    );
}
