import { Link } from "react-router-dom";
export default function FileTittle(props) {
    return (
        <header className={`page-header py-4 py-md-20 row align-items-center ${props.className ? props.className : ""} `}>
            <div className="col text-light">
                <h1>{props.title}</h1>
            </div>
            {props.actionButtons && props.actionButtons.length > 0 ? (
                <div className="col-auto align-self-start mx-n5">
                    {props.actionButtons.map((btn, idx) => {
                        return (
                            <Link key={idx} to={btn.btnPath} onClick={btn.btnEvent} className={`m-5 btn btn-${btn.btnVariant}`}>
                                {btn.btnIcon} {btn.btnText}
                            </Link>
                        );
                    })}
                </div>
            ) : (
                ""
            )}
        </header>
    );
}
