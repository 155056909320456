import React, { useState, useEffect, useCallback, useRef } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Card, Accordion, Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Page from "./Page";
import TitleBar from "./EditTittle";
import SingleGalleryRow from "./SingleGalleryRow";
import CenteredPopup from "./CenteredPopup";
import { MdEdit, MdDelete, MdSettings } from "react-icons/md";
import Cropper from "react-easy-crop";

import getCroppedImg from "../shared/util/cropImage";

function EditFile(props) {
    const [list, setList] = useState([]);
    const history = useHistory();
    const [files, setFiles] = useState([]);
    const [umodalShow, setUModalShow] = useState(false);
    const [row, setRow] = useState({
        Id: 0,
        FileName: "",
        FileServerPath: "",
        FileFullUrl: "",
        FileUrl: "",
        SiteId: 1,
        CreatedBy: 1,
        CreatedOn: "",
        Name: "",
        IsDeleted: 0,
        IsVisible: 1,
        ModifiedBy: 1,
        ModifiedOn: "",
    });
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [IsDisabled, setIsDisabled] = useState(false);
    const [nameError, setNameError] = useState("");
    const [validated, setValidated] = useState(0);
    const { id } = useParams();
    let content = "";
   
    const [detail, setDetail] = useState({
        Id: 0,
        FileName: "", 
        Name: "",  
        FileServerPath:"",
        FileFullUrl:"",
        FileUrl:"",                    
        IsVisible:true ,
    });

    useEffect(() => {
        let abortController = new AbortController();
        //let isComponentMounted = true;
        const fetchData = async () => {
            //   debugger;
            try {
                props.setLoader(true);
                let apiUrl = process.env.REACT_APP_IMAGEGALLERYAPI + "/getbyId/" + id;
                const response = await fetch(apiUrl, {
                    signal: abortController.signal,
                });
                // debugger;
                const json = await response.json();
                const resultPages = json ? json.list : "";
                if (resultPages && resultPages.length > 0) {
                    //debugger;
                    let firstRow = resultPages[0];
                    let isEnabled=false;
                    // || firstRow.IsVisible === true
                    if (firstRow.isVisible === 1 || firstRow.isVisible === true) {
                        isEnabled = true;
                    }
                    // debugger;
                    let newDetailPage = {
                        Id: firstRow.Id,
                        FileName: firstRow.FileName, 
                        Name: firstRow.FileName,  
                        FileServerPath:firstRow.FileServerPath,
                        FileFullUrl:firstRow.FileFullUrl,
                        FileUrl:firstRow.FileUrl,                 
                        IsVisible: isEnabled ,
                      
                    };
                    // debugger;
                    setDetail(newDetailPage);      
                }
                
                props.setLoader(false);
                //abortController = null;
            } catch (err) {
                props.setLoader(false);
                if (err.name === "AbortError") {
                    // Handling error thrown by aborting request
                }
            }
        };
        fetchData();
        // Side-effect logic...
        return () => {
            // Side-effect cleanup
            abortController.abort();
            console.log("unmounting...");
        };
    }, []);

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [aspect, setAspect] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [cropSize, setCropSize] = useState({ width: 40, height: 40 });
    const [Image, setImage] = useState("");
    const [rotation, setRotation] = useState(0);
    const zoomMaxLimit = 1;
    const [imageCropWidth, setImageCropWidth] = useState(100);
    const [imageCropHeight, setImageCropHeight] = useState(100);

    const onSaveImage = async (e) => {
        debugger;
        if (validate() < 0) {
            e.preventDefault();
            return -1;
        }
        try {
            const croppedImage = await getCroppedImg(Image, croppedAreaPixels, rotation);
            setCroppedImage(croppedImage);
            setImage(croppedImage);
            if (row) {
                row.FileFullUrl = croppedImage;
            }
        
        } catch (err) {
            props.setLoader(false);
            props.showToast("Error!", "Password couldn't be saved");
        } finally {
            setUModalShow(false);
        }
    };

    const showImageForCrop = (e) => {
        debugger;
        let localSrc = e.ImageFullUrl; //e.target.src;
        setImage(localSrc);
        setUModalShow(true);
        if (list && list.length > 0) {
            const selectedIndex = list.findIndex((item) => item.Id === e.Id);
            if (selectedIndex > -1) {
                setRow(list[selectedIndex]);
            }
        }
    };

    const singlefileSelectedHandler = (e) => {
        setIsDisabled(true);
        content = "";
        if (detail && detail.Content) {
            content = detail.Content;
        }
        //let imageList = "";
        const selectedFile = e.target.files[0];
        // let selectedFiles= (selectedFile > 1 ) ? 1 / 1:selectedFile;
        // let selectedFile = (1 > 3) ? "is greater" : "is less than";
        let FileSizeInMBs = selectedFile.size ? selectedFile.size / 1024 / 1024 : 0;
        if (selectedFile && FileSizeInMBs > 8) {
            setIsDisabled(false);
            setDetail({
                ...row,           
                ["FileName"]: "" ,       
                ["FileServerPath"]: "",
                ["FileFullUrl"]: "",
                ["FileUrl"]: ""      
                              
            });
            props.showToast("Information!", "File size can't be greater than 8MB.");
            return -1;
        }
        setFiles(...e.target.files);
        // var fileTypes = ['txt','docx'];  //acceptable file types
        const filename =
        selectedFile && selectedFile.name
                ? new Date().valueOf() +
                  "_" +
                  selectedFile.name.replace(/\\/g, "").replace(/ /g, "").replace(/'/g, "").replace(/"/g, "").replace(/`/g, "")
                // selectedFile.name.split('.').pop().toLowerCase();
                : "";
                // let isSuccess = fileTypes.indexOf(filename) > -1;
                debugger;
                if (selectedFile.type=="text/plain" || selectedFile.type=="application/msword") { 

                // if (isSuccess) { 
                // : "";
        const reader = new FileReader();
        reader.onload = (e) => {
            // debugger;
            let base64 = e.target.result;
            base64 = base64.replace(/^data:file\/(txt|doc);base64,/, "");
            // setFiles(reader.result);
            //imgRef.current = e.target.result;
            try {
                debugger;
                const settings = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        file: base64,
                        filename: filename,
                    }),
                    //signal: abortController.signal,
                };
                // setUModalShow(true);
                // setZoom(zoomMaxLimit);
                fetch(process.env.REACT_APP_SERVER_DOMAIN + `/uploaddetail`, settings)
                    .then((response) => response.json())
                    .then((responseTxt) => {
                         debugger;
                        let response = JSON.parse(responseTxt); 
                        //const uploadedImageUrl = response.data.url; // get saved image url
                        const uploadedFileUrl = response.fullurl; // get saved image url
                        // const uploadedFileName = filename;
                        const uploadedServerPath = response.serverpath;
                        const uploadurl = response.url;
                        setDetail({
                            ...detail,                            
                            // ["FileName"]: uploadedFileName,    
                            ["FileServerPath"]:uploadedServerPath ,          
                            ["FileFullUrl"]: uploadedFileUrl,
                            ["FileUrl"]: uploadurl                                                     
                        });
                        if (uploadedFileUrl) {
                            setIsDisabled(false);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        setIsDisabled(false);
                    });
            } catch (error) {
                setIsDisabled(false);
            }
        };
        reader.readAsText(selectedFile);
    }
    else{
        alert ("Please upload text or word file only");
    }
        //console.log(files);
    };

    const handleFormChanges = (e) => {
        //debugger;
        // const input = e.target;
        const input =e.target;
        const name = input.name;
        const value = input.type === "checkbox" ? input.checked : input.value;
        setDetail({ ...detail, [name]: value });
    };

    const validate = () => {
        //debugger;
       
            if (!detail.FileName) {
                setNameError("Name is mandatory.");
                return 0;
            }
            // setNameError("");
            return 0;
      
    };

    const HandleSaveMenu = async (e) => {
        //debugger;
        e.preventDefault();
        const form = e.currentTarget;
        if (validate() < 0) {
            e.preventDefault();
            return 0;
        }
        // if (form.checkValidity() === false) {
        //     e.stopPropagation();
        //     const formData = new FormData(form);
        //     const validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
        //         acc[key] = form.elements[key].validationMessage;
        //         return acc;
        //     }, {});
        //     alert(validationMessages);
        //     //console.log(validationMessages)
        // } else
         if (form.checkValidity() === true) {
            //setIsSubmitClicked(true);
            //debugger;
                        //debugger;

            const settings = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    Name: detail.Name,  
                    FileName: detail.FileName,  
                    FileServerPath:detail.FileServerPath,
                    FileFullUrl:detail.FileFullUrl,      
                    FileUrl:detail.FileUrl,
                    IsVisible: detail.IsVisible === true ? 1 : 0,
                    siteId: detail.IsVisible === true ? 1 : 0,
                    IsDeleted: false,
                    CreatedBy: 1,
                    ModifiedBy: 1,
                }),
                
            };
            // debugger;
            try {
                props.setLoader(true);
                let submitUrl = process.env.REACT_APP_IMAGEGALLERYAPI + "/" + id;
                // 
                const fetchResponse = await fetch(submitUrl, settings);
                if (!fetchResponse.ok) {
                    // => false
                    //const statusCode = fetchResponse.status; // => 404
                    const text = await fetchResponse.text();
                    //console.log(text);
                }
                // debugger;
                const retdata = await fetchResponse.json();
                let resultSet = retdata ? retdata.list : "";
                props.setLoader(false);
                // debugger;
                if (resultSet && resultSet.length > 0 && resultSet[0].affectedRows > 0) {
                    history.push("/backend/filelist");
                    props.showToast("Information!", "File has been saved successfully.");
                    
                }
                // debugger;
                //return resultSet;
            } catch (e) {
                console.log(e);
                props.setLoader(false);
            }
            // debugger;
        }
        setValidated(1);
    };

    const onAddSide = (e) => {
        // debugger;
        let newFileList = [...list];
        if (row.Id && row.Id > 0) {
            if (newFileList && newFileList.length > 0) {
                const selectedIndex = newFileList.findIndex((item) => item.Id === row.Id);
                if (selectedIndex > -1) {
                    newFileList[selectedIndex] = row;
                }
            }
        } else {
            row.Id = Math.max.apply(
                Math,
                newFileList.map(function (o) {
                    return o.Id + 1;
                })
            );
            if (!row.Id || (row.Id && row.Id === 0) || row.Id === -Infinity) {
                row.Id = 1;
            }
            newFileList.push(row);
        }
        
        setDetail({
            Id: 0,
        Name: "",  
        FileName: "",  
        FileServerPath:"",
        FileFullUrl:"",
        FileUrl:"",
                         
        IsVisible: 1 ,
        });
    };

    

    const onEditSlide = (e) => {
        // debugger;
        if (list && list.length > 0) {
            const selectedIndex = list.findIndex((item) => item.Id.toString() === e.target.id);
            if (selectedIndex > -1) {
                setRow(list[selectedIndex]);
            }
        }
    };

    function cancel ()
    {
        history.push("/backend/filelist");
    }
    return (
        <Page breadcrumb={props.breadcrumb} pageName={props.pageName} fluid className="bg-dark">
            <form noValidate validated={validated} onSubmit={HandleSaveMenu} className="user-form">
                <Card className="bg-dark border-warning">
                    <Card.Header>
                        <TitleBar title="File detail" />
                    </Card.Header>
                    <Card.Body className="border-top-5">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label className="form-label text-light" htmlFor="FileName">
                                    Name
                                </label>
                                <input
                                    name="FileName"
                                    required
                                    id="FileName"
                                    className={`form-control bg-light ${nameError ? "is-invalid" : ""}`}
                                    value={detail.FileName}
                                    onChange={handleFormChanges}
                                />
                                {nameError ? <div className="invalid-feedback">{nameError}</div> : ""}
                                {!nameError && <div className="valid-feedback">Looks good!</div>}
                            </div>
                        
                            <div className="form-group col-md-4">
                                <label className="form-label">&nbsp;</label>
                                <div className="custom-control custom-switch">
                                    <input
                                        id="IsVisible"
                                        name="IsVisible"
                                        checked={detail.IsVisible}
                                        onChange={handleFormChanges}
                                        type="checkbox"
                                        className="custom-control-input"
                                    />
                                    <label title="" htmlFor="IsVisible" className="custom-control-label text-light">
                                        Active
                                    </label>
                                </div>
                            </div>
                        </div>
                        <SingleGalleryRow
                            data={detail}
                            setRow={setDetail}
                            onUpload={singlefileSelectedHandler}
                            // onAddSide={onAddSide}
                            IsDisabled={IsDisabled}
                            className="rounded"
                        />
                        <div className="uploaded-image styleImagethumbnail">
                        <span className="bg-wrap" style={{backgroundImage: `url(${detail.FileFullUrl})`}}></span>
                        <span className="image-name sr-only">{detail.FileName}</span>
                    </div>

                    </Card.Body>
                    <Card.Footer>
                   
                        <div className="btn-holder text-right pd-5">
                            <Button type="submit" variant="warning" disabled={isSubmitClicked}>
                                Update
                            </Button>
                            <Button type="cancel" variant="dark" onClick={cancel}>
                                Cancel
                            </Button>
                            </div>                                    
                    </Card.Footer>
                </Card>
            </form>
          
        </Page>
    );
}

export default withRouter(EditFile);
